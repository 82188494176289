<template>
  <div>
    <date-filter :filters="payload.filters" />
    <Bar :chart-data="chartData" v-if="loaded" :height="200" />
    <ul>
      <li></li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import DateFilter from "../../components/DateFilter.vue";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "StatPage",
  components: {
    Bar,
    DateFilter,
  },
  data() {
    return {
      loaded: false,
      payload: {
        filters: {
          created_from: moment().subtract(1, "month").toDate(),
          created_to: moment().toDate(),
        },
      },
    };
  },
  computed: {
    ...mapGetters("stats", ["searchProcesses"]),
    processingPayload() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      if (typeof this.payload.filters !== "undefined") {
        if (typeof this.payload.filters.created_from !== "undefined") {
          if (this.payload.filters.created_from !== null) {
            payload.filters.created_from = parseInt(
              moment(this.payload.filters.created_from).format("X")
            );
          } else {
            delete payload.filters.created_from;
          }
        }
        if (typeof this.payload.filters.created_to !== "undefined") {
          if (this.payload.filters.created_to !== null) {
            payload.filters.created_to = parseInt(
              moment(this.payload.filters.created_to).format("X")
            );
          } else {
            delete payload.filters.created_to;
          }
        }
      }
      return payload;
    },
    chartData() {
      const data = {
        labels: [],
        datasets: [{ backgroundColor: "#055A6B", data: [], label: "" }],
      };
      this.searchProcesses.forEach((element) => {
        data.labels.push(`${element.max}`),
          data.datasets[0]["data"].push(element.total);
      });
      return data;
    },
    chartOptions() {
      return { responsive: true };
    },
  },
  methods: {
    loadStatList() {
      this.loaded = false;
      this.$store
        .dispatch("stats/getSearchProcesses", this.processingPayload)
        .then(() => {
          this.loaded = true;
        });
    },
  },
  mounted() {
    this.loadStatList();
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
