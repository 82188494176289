<template>
  <div class="columns">
    <div class="column">
      <b-field :label="this.$t('birthday_date.from')">
        <b-datepicker
          placeholder="Type or select a date..."
          icon="calendar-day"
          locale="ru-Ru"
          v-model="filters.created_from"
          :first-day-of-week="1"
          editable
          :min-date="minDate"
          :max-date="minMaxDate"
        >
        </b-datepicker>
      </b-field>
    </div>
    <div class="column">
      <b-field :label="this.$t('birthday_date.to')">
        <b-datepicker
          placeholder="Type or select a date..."
          icon="calendar-day"
          locale="ru-Ru"
          v-model="filters.created_to"
          :first-day-of-week="1"
          editable
          :min-date="maxMinDate"
          :max-date="maxDate"
        >
        </b-datepicker>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateFilter",
  props: ["filters"],
  data() {
    return {
      minDate: new Date(2022, 0, 0),
      maxDate: new Date(),
    };
  },
  computed: {
    minMaxDate() {
      return this.filters.created_to;
    },
    maxMinDate() {
      return this.filters.created_from
    }
  }
};
</script>